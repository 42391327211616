<template>
<v-container fluid>
    <v-expansion-panels>

      <!-- How to Login user -->
      <v-expansion-panel>
        <v-expansion-panel-header>
           <h2 style="font-family:Arial"> How to Login User Account </h2>
        </v-expansion-panel-header>
           <v-expansion-panel-content style="font-family:Arial;font-weight:bold">
            1. Enter your valid email address that is registered in DSS AWS.
            <br>
            2. Enter your password.
            <br>
            3. Click "Login" button.
            <br>
            4. Click "Users Manual" if you want to check the system manual.
            <br>
            <br>
            <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              max-height="500"
              max-width="500"
              src="https://s3.us-east-2.amazonaws.com/rulebook.files/UserManual/DSSLOGIN.png"
            ></v-img>
            <br>
            
        </v-expansion-panel-content>      
      </v-expansion-panel>

      <!-- Register New User Domain -->
      <v-expansion-panel>
        <v-expansion-panel-header>
           <h2 style="font-family:Arial"> How to Register New User Domain ( Administrator ) </h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
           <v-btn @click="downloadAttachment()" class="primary" small >
            <v-icon> mdi-download</v-icon>
            <span style="font-family:Arial"> Click Me</span>
           </v-btn>
        </v-expansion-panel-content>      
      </v-expansion-panel>

      <!-- Register New User -->
      <v-expansion-panel>
        <v-expansion-panel-header>
           <h2 style="font-family:Arial"> How to Register New User ( Administrator ) </h2>
        </v-expansion-panel-header>
           <v-expansion-panel-content style="font-family:Arial;font-weight:bold">
            <br>
             <span> * Click "Manage Users" under Employee name </span>
            <br>
            1. Click "New" button.
            <br>
            2. Enter the all required fields in dialog.
            <br>
            3. Click save to add.
            <br>
            <br>
            <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              max-height="1000"
              max-width="1000"
              src="https://s3.us-east-2.amazonaws.com/rulebook.files/UserManual/dss-master-user.png"
            ></v-img>
            
        </v-expansion-panel-content>      
      </v-expansion-panel>

      <!-- Register New keyword ( multiple keywords )-->
       <v-expansion-panel>
        <v-expansion-panel-header>
           <h2 style="font-family:Arial"> How to Register New Multiple keywords ( Administrator) </h2>
        </v-expansion-panel-header>
           <v-expansion-panel-content style="font-family:Arial;font-weight:bold">
            <br>
             <h2 style="color:blue"> Adding New Keyword </h2 >
            <br>
            1. Click the name of the user on the top right position, then select "Multiple Master".
            <br>
            <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              max-height="1000"
              max-width="1000"
              src="https://s3.us-east-2.amazonaws.com/rulebook.files/UserManual/multiple-user1.png"
            ></v-img>

            <br>
            2. Click "New" button.
            <br>
            3. Enter Group Name and sub group name.
            <br>
            4.  Click "+" button to register new keyword.
            <br>
            5. Enter keyword.
            <br>
            6.  Click "save" button to update the record.
            <br>
            7. Click "close" button to close the form view.

            <br>
              <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              max-height="1000"
              max-width="1000"
              src="https://s3.us-east-2.amazonaws.com/rulebook.files/UserManual/multiple-user2.png"
            ></v-img>

            <br>
             <h2 style="color:blue"> Updating Keyword </h2 >
            <br>

            1. Click "Edit" button in the data table.
            <br>
            2. Edit Groupname, subgroup or keywords list.
            <br>
            3. Click "Save" button to update the records.
            <br>
             <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              max-height="1000"
              max-width="1000"
              src="https://s3.us-east-2.amazonaws.com/rulebook.files/UserManual/multiple-user3.png"
            ></v-img>
            <br>

        </v-expansion-panel-content>      
      </v-expansion-panel>

      <!-- How to register new feedback -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h2 style="font-family:Arial"> How to Register New Feedback </h2>
          </v-expansion-panel-header>
            <v-expansion-panel-content style="font-family:Arial;font-weight:bold">
              1. Click the name of the user on the top right position, then select "Feedback".
              <br>
              <v-img
                lazy-src="https://picsum.photos/id/11/10/6"
                max-height="1000"
                max-width="1000"
                src="https://s3.us-east-2.amazonaws.com/rulebook.files/UserManual/feedback-1.png"
              ></v-img>

              <br>
              2. Click "New" button.
              <br>
              3. Enter the all required fields in the feedback dialog, Feedback number is automatically generated by the system.
              <br>
              4. Click "Save" to register new feedback.
              <br>
                <v-img
                lazy-src="https://picsum.photos/id/11/10/6"
                max-height="1000"
                max-width="1000"
                src="https://s3.us-east-2.amazonaws.com/rulebook.files/UserManual/feedback-2.png"
              ></v-img>
              <br>
        </v-expansion-panel-content>      
      </v-expansion-panel>

      
      <!-- How to change password -->
      <v-expansion-panel>
        <v-expansion-panel-header>
           <h2 style="font-family:Arial"> How to Change User Password </h2>
        </v-expansion-panel-header>
           <v-expansion-panel-content style="font-family:Arial;font-weight:bold">
            1. Click the name of the user on the top right position, then select "Change Password".
            <br>
            <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              max-height="1000"
              max-width="1000"
              src="https://s3.us-east-2.amazonaws.com/rulebook.files/UserManual/change-password1.png"
            ></v-img>
            <br>
            2. Enter your old password, next Enter your new password, then re-type again the password for confirmation.
            <br>
            3. Click "Save" button to update the changes.
            <br>
            <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              max-height="1000"
              max-width="1000"
              src="https://s3.us-east-2.amazonaws.com/rulebook.files/UserManual/change-password2.png"
            ></v-img>
            <br>
        </v-expansion-panel-content>      
      </v-expansion-panel>



       <!-- All Documents -->
       <v-expansion-panel>
        <v-expansion-panel-header>
           <h2 style="font-family:Arial"> All Documents Page </h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content style="font-family:Arial;font-weight:bold">
            <span> How to search documents in all document page: </span> 
            <br>
            1. Click 全文検索 Page and Enter search word in the textfield.
            <br>
            2. Select Conditional Option ( AND, OR , MULTI )
             <br>
               <span style="margin-left: 20px;">  "OR" The page will be displayed if all the entered keywords are included, Note: Keywords must be 2 words separated by space.</span>
            <br>
               <span style="margin-left: 20px;">  "AND" Display the pages that contain all the keywords entered. Note: Keywords must atleast 1 word, otherwise 2 words separated by space.</span>             
            <br>
               <span style="margin-left: 20px;">   "MULTI" displays the page under the same conditions as "OR", including registered synonyms. </span>   
            <br>
            3. Enter Except Word ( optional ) , the page will be displayed all the entered keywords except the entered word in the field.
            <br>
            4. Press Search button.
            <br>
            5. Press this button, if you want to filter all favorites documents with your account.
            <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              max-height="1000"
              max-width="1000"
              src="https://s3.us-east-2.amazonaws.com/rulebook.files/UserManual/search-alldocuments.png"
            ></v-img>
        </v-expansion-panel-content>      
      </v-expansion-panel>
      
       <!-- Rulebook page -->
       <v-expansion-panel>
        <v-expansion-panel-header>
           <h2 style="font-family:Arial"> Rulebook Page </h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content style="font-family:Arial;font-weight:bold">
            1. Enter search word in the textfield.
            <br>
            2. Select Conditional Option ( AND, OR , MULTI )
            <br>
               <span style="margin-left: 20px;">  "OR" The page will be displayed if all the entered keywords are included. Note: Keywords must be 2 words separated by space. </span>
            <br>
               <span style="margin-left: 20px;">  "AND" Display the pages that contain all the keywords entered. Note: Keywords must atleast 1 word, otherwise 2 words separated by space. </span>             
            <br>
               <span style="margin-left: 20px;">   "MULTI" displays the page under the same conditions as "OR", including registered synonyms. </span>   
               
            <br>
            3. Enter Except Word ( optional ) , the page will be displayed all the entered keywords except the entered word in the field.
            <br>
            4. Press Search button.
            <br>
            5. Select filter 
            <br>
            6. Press the button if you want to filter by favorites.
            <br>
            7. Press the button if you want to view the rulebook history
            <br>
            <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              max-height="1000"
              max-width="1000"
              src="https://s3.us-east-2.amazonaws.com/rulebook.files/UserManual/search-rulebook.png"
            ></v-img>
            <br>

            8. Rulebook page printing.
            <br>
            9. You can register the rulebook page as a favorite and display it in the filter search.
            <br>
            10. You can add feedback for the rulebook page.
            <br>
            11. View Rulebook history logs.
            <br>
            <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              max-height="1000"
              max-width="1000"
              src="https://s3.us-east-2.amazonaws.com/rulebook.files/UserManual/search-rulebook-content.png"
            ></v-img>
        </v-expansion-panel-content>      
      </v-expansion-panel>

      <!-- Japan Memo -->
      <v-expansion-panel>
        <v-expansion-panel-header>
           <h2 style="font-family:Arial"> Japan Memo Page </h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content style="font-family:Arial;font-weight:bold">
            <br>
            1. Enter search word in the textfield.
            <br>
            2. Select Conditional Option ( AND, OR , MULTI )
             <br>
               <span style="margin-left: 20px;">  "OR" The page will be displayed if all the entered keywords are included. Note: Keywords must be 2 words separated by space. </span>
            <br>
               <span style="margin-left: 20px;">  "AND" Display the pages that contain all the keywords entered. Note: Keywords must atleast 1 word, otherwise 2 words separated by space.  </span>             
            <br>
               <span style="margin-left: 20px;">   "MULTI" displays the page under the same conditions as "OR", including registered synonyms. </span>   
            <br>
            3. Enter Except Word ( optional ) , the page will be displayed all the entered keywords except the entered word in the field.
            <br>
            4. Press Search button.
            <br>
            5. Filter options.
            <br>
            6. Filter by Favorites.
            <br>
            7. Click the title to download the document.
            <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              max-height="1000"
              max-width="1000"
              src="https://s3.us-east-2.amazonaws.com/rulebook.files/UserManual/search-japanmemo.png"
            ></v-img>
        </v-expansion-panel-content>      
      </v-expansion-panel>

      <!-- JWW / DXF -->
      <v-expansion-panel>
        <v-expansion-panel-header>
           <h2 style="font-family:Arial"> JWW / DXF  </h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content style="font-family:Arial;font-weight:bold">
            <br>
            1. Enter search word in the textfield.
            <br>
            2. Click the hyperlink to download JWW or DXF file.
            <br>
            <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              max-height="1000"
              max-width="1000"
              src="https://s3.us-east-2.amazonaws.com/rulebook.files/UserManual/search-jww-dxf.png"
            ></v-img>
        </v-expansion-panel-content>      
      </v-expansion-panel>

      <!-- DXF -->
      <v-expansion-panel>
        <v-expansion-panel-header>
           <h2 style="font-family:Arial"> DXF  </h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content style="font-family:Arial;font-weight:bold">
            <br>
            1. Enter search word in the textfield.
            <br>
            2. Filter the documents.
            <br>
            3. Click the hyperlink to download DXF file.
            <br>
            <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              max-height="1000"
              max-width="1000"
              src="https://s3.us-east-2.amazonaws.com/rulebook.files/UserManual/search-dxf.png"
            ></v-img>
        </v-expansion-panel-content>      
      </v-expansion-panel>

      <!--Shiyou Manual  -->
      <v-expansion-panel>
        <v-expansion-panel-header>
           <h2 style="font-family:Arial"> Shiyo Manual  </h2>
        </v-expansion-panel-header>
           <v-expansion-panel-content style="font-family:Arial;font-weight:bold">
            <br>
            1. Enter search word in the textfield.
            <br>
            2. Select Conditional Option ( AND, OR , MULTI )
            <br>
               <span style="margin-left: 20px;">  "OR" The page will be displayed if all the entered keywords are included. Note: Keywords must be 2 words separated by space. </span>
            <br>
               <span style="margin-left: 20px;">  "AND" Display the pages that contain all the keywords entered. Note: Keywords must atleast 1 word, otherwise 2 words separated by space. </span>             
            <br>
               <span style="margin-left: 20px;">   "MULTI" displays the page under the same conditions as "OR", including registered synonyms. </span>   
            <br>
            3. Enter Except Word ( optional ) , the page will be displayed all the entered keywords except the entered word in the field.
            <br>
            4. Press Search button.
            <br>
            5. Filter options.
            <br>
            6. Filter by Favorites.
            <br>
            7. Click to view shiyo history.
            <br>
            <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              max-height="1000"
              max-width="1000"
              src="https://s3.us-east-2.amazonaws.com/rulebook.files/UserManual/search-shiyo.png"
            ></v-img>
            <br>
            8. Shiyou Manual page printing. 
            <br>
            9. You can register the shiyo manual page as a favorite and display it in the filter search.
            <br>
            10. You can add feedback for the shiyo manual document 
            <br>
             <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              max-height="1000"
              max-width="1000"
              src="https://s3.us-east-2.amazonaws.com/rulebook.files/UserManual/search-shiyo-content.png"
            ></v-img>
        </v-expansion-panel-content>      
      </v-expansion-panel>


      <!-- CAD Request Materials -->
      <v-expansion-panel>
        <v-expansion-panel-header>
           <h2 style="font-family:Arial"> CAD Request Materials </h2>
        </v-expansion-panel-header>
           <v-expansion-panel-content style="font-family:Arial;font-weight:bold">
            <br>
            1. Click the hyperlink to open the file in PDF viewer.
            <br>
            <br>
            <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              max-height="1000"
              max-width="1000"
              src="https://s3.us-east-2.amazonaws.com/rulebook.files/UserManual/search-cadrequest.png"
            ></v-img>
            
        </v-expansion-panel-content>      
      </v-expansion-panel>

     <!-- Keywords Logs -->
     <v-expansion-panel>
        <v-expansion-panel-header>
           <h2 style="font-family:Arial"> Keywords LOGS Monitoring </h2>
        </v-expansion-panel-header>
           <v-expansion-panel-content style="font-family:Arial;font-weight:bold">
            1. Click "Keyword logs" button which is highlighted of red box.
            <br>
            <span> * You will monitor the number of logs per keyword, you can also search specific keyword.  </span>
            <br>
            <br>
            <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              max-height="1000"
              max-width="1000"
              src="https://s3.us-east-2.amazonaws.com/rulebook.files/UserManual/DSSKEYWORDLOGS.png"
            ></v-img>
            
        </v-expansion-panel-content>      
      </v-expansion-panel>


      <!-- Users logs -->
      <v-expansion-panel>
        <v-expansion-panel-header>
           <h2 style="font-family:Arial"> Users LOGS Monitoring </h2>
        </v-expansion-panel-header>
           <v-expansion-panel-content style="font-family:Arial;font-weight:bold">
            1. Click "User logs" button which is highlighted of red box.
            <br>
            2. You can search username, by entering username.
            <br>
            3. You can filter by month, by selecting specific month.
            <br>
            <br>
            <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              max-height="1000"
              max-width="1000"
              src="https://s3.us-east-2.amazonaws.com/rulebook.files/UserManual/DSSKEYUSERLOGS.png"
            ></v-img>
            
        </v-expansion-panel-content>      
      </v-expansion-panel>

      <!-- System LOGS -->
       <v-expansion-panel>
        <v-expansion-panel-header>
           <h2 style="font-family:Arial"> System LOGS Monitoring </h2>
        </v-expansion-panel-header>
           <v-expansion-panel-content style="font-family:Arial;font-weight:bold">
            1. Click "System logs" button which is highlighted of red box.
            <br>
            <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              max-height="1000"
              max-width="1000"
              src="https://s3.us-east-2.amazonaws.com/rulebook.files/UserManual/DSSSYSTEMLOGS2.png"
            ></v-img>
            <br>
            * You will monitor the list of versions of the system including the revision details and images.
            <br>
            * Latest version will be on the top of the list.
            <br>
            <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              max-height="1000"
              max-width="1000"
              src="https://s3.us-east-2.amazonaws.com/rulebook.files/UserManual/DSSSYSTEMLOGS.png"
            ></v-img>
            
        </v-expansion-panel-content>      
      </v-expansion-panel>
    </v-expansion-panels>
    
    <!-- <v-layout align-center justify-center v-if="loadingUserManual">
          <a-spin class="mt-2" tip="Loading..." />
    </v-layout>
    <v-card style="margin-left:25px" flat v-else>
     <span v-html="userManual.usermanualAWSDetails"></span>
     </v-card> -->

</v-container>
</template>
<script>
import axios from 'axios'
import AWS from "aws-sdk";
import tunnel from "tunnel";
import config from "../config";
// import * as binconv from "binconv";
import converter from "base64-arraybuffer";
export default {
    data(){
        return{
        userManual:{},
        loadingUserManual: true,
        // manualList: [
        //  'How to Register New User Domain',
        //  'How to Update User Domain',
        //  'How to Delete User Domain'
        // ]
        }
    },
    created(){
      if(!this.userInfo){
        this.$router.push('/login')
      }
      let url = `${this.api}get/usermanual`
      this.LoadingImage(url)
    },
    methods:{
    // 2022-06-23
    downloadAttachment(){
      window.open("https://s3.us-east-2.amazonaws.com/rulebook.files/PDF_Files/DSSKaizen.pdf")
    },
    LoadingImage(url){
       axios.defaults.headers.common["x-api-key"] =
      "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
    axios.get(url).then(async(res) => {
      let content =  res.data.usermanualAWSDetails
    //   for(let i=0;i<content.length;i++){
       let indices = [];
        let imgName = [];

        let findStr = new RegExp(
          "<img",
          "g"
        );

        let result = "";
        while ((result = findStr.exec(content))) {
          indices.push(result.index);
        }

        if (indices.length > 0) {
          imgName = indices.map((index) => {
            return content.substring(index+10, index+15);
          });
        }
        // console.log(imgName)
        for (let j=0; j<imgName.length;j++){
        // console.log(`replace${j}`)
        let deleteSrc = new RegExp(`src="${imgName[j]}"`)
        content = content.replace(deleteSrc,`<img src='../loading.gif' height="auto" width="auto"`)
        }
        res.data.usermanualAWSDetails = content     
       this.userManual = await res.data;
           if (this.userInfo == "") {
          this.userManual.usermanualAWSDetails = this.userManual.usermanualAWSDetails.substring(
            0,
            63791
          );
        } else {
          this.userManual.usermanualAWSDetails = this.userManual.usermanualAWSDetails.substring(
            63791,
            9999999
          );
        }
       this.ReplaceImageFromS3(url)
    })
   

    },
    ReplaceImageFromS3(url){
        axios.defaults.headers.common["x-api-key"] =
      "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
    axios.get(url).then((res) => {
      
      let data = res.data.usermanualAWSDetails
      const strToBase64 = (str) => {
          let indices = [];
        let imgName = [];
        let findStr = new RegExp(
          "<img",
          "g"
        );

        let result = "";
        while ((result = findStr.exec(str))) {
          indices.push(result.index);
        }

        if (indices.length > 0) {
          imgName = indices.map((index) => {
            return str.substring(index+10, index+15);
          });
        }
        return imgName;
      };
      let s3Keys = strToBase64(data)
    //   console.log(s3Keys)
      let promises = [];
      if (s3Keys.length > 0) {
        for (let i = 0; i < s3Keys.length; i++) {
          promises.push(this.ImageSrc(s3Keys[i]));
        }
        let newContent = res.data.usermanualAWSDetails
        Promise.all(promises).then((datas) => {
          for (let i = 0; i < datas.length; i++) {
            //   console.log(datas)
            let toReplace = new RegExp(
              `${s3Keys[i]
              }`,
              "g"
            );
            newContent = newContent.replace(toReplace, datas[i]);
            // console.log(newContent)
          }
          // console.log(newContent.split("-----"));
          res.data.usermanualAWSDetails = newContent
          this.userManual = res.data;
        //   this.loading = false;
         if (this.userInfo == "") {
          this.userManual.usermanualAWSDetails = this.userManual.usermanualAWSDetails.substring(
            0,
            63791
          );
        } else {
          this.userManual.usermanualAWSDetails = this.userManual.usermanualAWSDetails.substring(
            63791,
            9999999
          );
        }
        this.loadingUserManual = false
        });
      } 
    
    });
    
    },
      ImageSrc(imgName) {
      return new Promise((resolve) => {
        var tunnelingAgent = tunnel.httpsOverHttp({
          proxy: {
            // Proxy settings
            host: "hrdproxy.hrd-s.com",
            port: 81,
            proxyAuth: "administrator:system",
          },
        });
        // console.log(tunnelingAgent, "proxy");

        AWS.config.update({
          httpOptions: { agent: tunnelingAgent },
          region: "us-east-2",
          credentials: config,
        });
        var s3 = new AWS.S3({
          AccessKeyID:this.accessKeyId,
          SecretAccessKey: this.secretAccessKey,
          Region: "us-east-2",
          params: {
            bucket: "rulebook.files",
          },
          // endpoint:'https://s3.us-east-2.amazonaws.com/rulebook.files/'
        });

        var options = {
          Bucket: "rulebook.files",
          Key: `ManualImages/${imgName}`,
          // Key: "JWW_Files/02.jww",
          // Key: "9557811-2019-0905D07-Advance_Series__INDIVIDUAL_.xls",
        };
        // console.log(options, "options");
     s3.getObject(options, function(err, data) {
          if (err) {
            // console.log(err, err.stack);
            // return false;
           
          }
          // an error occurred
          else {
            // console.log(data, "s3Data");

            // const base64 = binconv.uint8ArrayToBase64(data.Body);
            const base64 = converter.encode(data.Body);
            let content = `data:${data.ContentType};base64,`;
            // console.log(content);
            // console.log("ConvertBase64", base64);
            let finalcontent = `${content}${base64}`;
            // console.log(finalcontent);
            // var reader = new FileReader();

            // reader.readAsDataURL(base64);
            this.imagesrc = finalcontent;
            // successful response
            // console.log(err);
            resolve(this.imagesrc);
          }
        })
     
      })
    },
    }
}
</script>
<style scoped>
h2{
    text-align:left !important;
}
</style>